@import "bootstrap-overrides";
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";

$twitter: #00aced;
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$linkedin: #007bb6;
$youtube: #bb0000;
$vimeo: #aad450;
$tumblr: #32506d;
$instagram: #517fa4;
$flickr: #ff0084;
$dribbble: #ea4c89;
$quora: #a82400;
$foursquare: #0072b1;
$forrst: #5B9A68;
$vk: #45668e;
$wordpress: #21759b;
$stumbleupon: #EB4823;
$yahoo: #7B0099;
$blogger: #fb8f3d;
$soundcloud: #ff3a00;

body {
	background-color: #0E0E0E;
}

/*============= GLOBAL =============*/

ul.links {
	a {
		color: #9A9A9A;
		font-size: .8em;
		transition: color 0.2s ease;

		&:hover {
			color: #fff;
			text-decoration: none;
		}

		&.twitter {
			color: $twitter;
		}

		&.facebook {
			color: $facebook;
		}

		&.youtube {
			color: $youtube;
		}

		&.website:hover {
			color: #ce2a4a;
		}

	}
}

a {
	color: #ce2a4a;
	&:hover {
		color: #be1d3c;
		text-decoration: none;
	}

	&:active:focus {
		color: #be1d3c;
	}
}

.btn-primary {
    border-radius: 5em;
    background-color: #ce2a4a;
    border-color: #ce2a4a;
    padding: .5em 2em;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    font-size: 1em;
    transition: color 0.2s ease !important;
    color: #f1f1f1 !important;

    &:hover {
		background-color: #be1d3c;
		border-color: #ce2a4a;
		text-decoration: none;
	}

	&:active:focus {
		background-color: #be1d3c;
		border-color: #ce2a4a;
	}
}

.btn-default {
    border-radius: 5em;
    background-color: #999;
    border-color: #999;
    padding: .5em 2em;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    font-size: 1em;
    transition: color 0.2s ease !important;
    color: #f1f1f1 !important;

    &:hover {
		background-color: #be1d3c;
		border-color: #ce2a4a;
		text-decoration: none;
	}

	&:active:focus {
		background-color: #be1d3c;
		border-color: #ce2a4a;
	}
}

.btn-sm {
	font-size: 0.8em;
}

h2 {
    text-transform: uppercase;
    font-size: 3em;
    color: #fefefe;
    font-weight: 900;
}

h4 {
	color: #fefefe;
    text-transform: uppercase;
    font-size: 1em;
    padding-bottom: .8em;
}

h5 {
	color: #fefefe;
    text-transform: uppercase;
    font-size: .8em;
    padding-bottom: .8em;
    font-weight: 400;
    letter-spacing: .2em;
    padding-bottom: 0;
}

h6 {
	color: #fefefe;
    font-size: .8em;
    font-weight: 400;
    letter-spacing: .1em;
    color: #848484;
}

p {
	color: #848484;
	font-weight: 200;
    font-size: .9em;
}

.navbar-critics-subnav {
	padding: 0;
	background: #ededed;
	padding: 0.75em 0;
	margin: 0 0 1em 0;
	ul {
		li {
			a {
				color: #9A9A9A;
				font-family: 'Open Sans', sans-serif;
				text-transform: uppercase;
				font-size: .8em;
				padding-bottom: 10px;

				&:hover {
					color: #ce2a4a;
				}
			}

			&.active {
				margin-bottom: -13px;
				a {
					color: #9A9A9A;
					border-bottom: 3px solid #ce2a4a;

				}
			}
		}
	}
}

.navbar-critics {
	padding: 1em 0;
	margin: 0;
	background: #080808;
	font-family: 'Open Sans', sans-serif;
	text-transform: uppercase;
	font-size: .8em;


	.navbar-nav {
		a {
			color: #fff;
			transition: color 0.2s ease;

			&:hover {
				color: #ce2a4a;
	    		text-decoration: none;
			}
			&:active {
				color: #ce2a4a;
			}



		}

	}

}

#brand img {
	margin-top: -0.25em;
	height: 2em;
}

.nav-item {
	span {

	}
	span::before {
	    content: " ";
		border-left: 1px solid #444;
		margin: 0 0.5em;
	}
}

.review {
	background: none;
	border: none;

	.card-img-top {
		border-radius: 0.25rem;
	    -webkit-box-shadow: 0px 5px 18px -5px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 5px 18px -5px rgba(0,0,0,0.75);
		box-shadow: 0px 5px 18px -5px rgba(0,0,0,0.75);
		margin-bottom: 1em;
	}

	.card-img {
		margin-bottom: 1em;
	}

	h6 {
		padding-bottom: 1em;

		a {
			color: #fff;
			transition: color 0.2s ease;

			&:hover {
				color: #ce2a4a;
    			text-decoration: none;
			}
		}
	}
}

.review-detail {
	.share {
		a.twitter {
			color: $twitter;
		}
		a.facebook {
			color: $facebook;
		}
	}
}

.card-block {
	font-size: .8em;
    color: #9A9A9A;
    transition: color 0.2s ease;
    padding: 0;
    padding-bottom: 1em;
}



.home {

}

.review-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
	top: 0;
	left: 0;
	transition: opacity .5s ease-in-out;

	&:hover {
	    opacity: 0;
	}
}

#home-carousel {

	.shadow {
	    position: absolute;
	    width: 100%;
	    height: 100%;
		-webkit-box-shadow: inset 0px -69px 98px 43px rgba(17,17,17,1);
		-moz-box-shadow: inset 0px -69px 98px 43px rgba(17,17,17,1);
		box-shadow: inset 0px -69px 98px 43px rgba(17,17,17,1);
	    top: 0;
	    left: 0;
	}

	.carousel-caption {
		top: 16em;
		a {
			color: #fbfbfb;
			text-decoration: none;
		}
		h2 {
			padding-bottom: .5em;
		    text-transform: uppercase;
		    font-size: 3em;
		}
	}
	.carousel-indicators {
		top: 33em;
	}
}

.inner {
	margin-top: 4em;

	.sidebar {

	}

}

/*============= SIDEBAR =============*/

.home {
	.main, .sidebar {
	}
}

.sidebar {
	h4 {
		color: #fefefe;
	    text-transform: uppercase;
	    font-size: 1em;
	    padding-bottom: .8em;
	}

	ul {
		list-style-type: none;
		padding-left: 0;
		padding-bottom: 1em;
	}

	p {
		color: #9A9A9A;
		font-size: .8em;
	}
	.newsletter-input {
	    background-color: #0E0E0E;
	    border: none;
	    border-bottom: 1px solid #373A3C;
	    padding-bottom: .5em;
	}
	.poster {
		margin-bottom: 2em !important;
	}

}

/*============= FOOTER =============*/

footer {
	border-top: 1px solid #333;
	padding-top: 1em;
	margin: 3em 0 1em 0;
	font-size: 0.85em;

	a {
		color: #373a3c;
		&:hover {
			color: #ce2a4a
		}
	}
	.twitter {
		font-size: 2em;
	}
	.copy {
		margin-top: .8em;
		display: block;
	}
}

/*============= CARD STYLES =============*/

.critic {
	a {
		text-align: center;
	    display: block;
	    text-transform: uppercase;
	    font-size: .7em;
	    padding-bottom: 2em;
	    color: #9A9A9A;
	    transition: color 0.2s ease;

	    &:hover {
	    	color: #fff;
	    	text-decoration: none;
	    }
	}

	.avatar {
	    border-radius: 0.25rem;
	    -webkit-box-shadow: 0px 5px 18px -5px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 5px 18px -5px rgba(0,0,0,0.75);
		box-shadow: 0px 5px 18px -5px rgba(0,0,0,0.75);
		margin-bottom: 1em;
	}
}

.movie {
	a {
		text-align: center;
	    display: block;
	    text-transform: uppercase;
	    font-size: .7em;
	    padding-bottom: 2em;
	    color: #9A9A9A;
	    transition: color 0.2s ease;

	    &:hover {
	    	color: #fff;
	    	text-decoration: none;
	    }
	}

	.poster {
	    border-radius: 0.25rem;
	    -webkit-box-shadow: 0px 5px 18px -5px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 5px 18px -5px rgba(0,0,0,0.75);
		box-shadow: 0px 5px 18px -5px rgba(0,0,0,0.75);
		margin-bottom: 1em;
	}
}



ul.pagination {
	li.page-item {
		&.disabled {
			&:hover {
				a {
					background: inherit;
				}
			}
		}

		a.page-link {
			border: none;
			background-color: inherit;
			color: #ce2a4a;
		}

		&.active {
			background-color: inherit;
			a {
				color: #fff;

				&:hover {
					text-decoration: none;
				}
			}

			&:hover {
				a {
					border: none;
					background: inherit;
				}
			}
		}
	}
}

/*============= MOVIE PAGE =============*/





// Extra small devices (portrait phones, less than 34em)
@media (max-width: 33.9em) {
}

// Small devices (landscape phones, less than 48em)
@media (max-width: 47.9em) {

	.home {
		margin-top: 7em;
	}

}

// Medium devices (tablets, less than 62em)
@media (max-width: 61.9em) {
	#home-carousel {

		.carousel-caption {
			top: 5em;
			a {
			}
			h2 {
			    font-size: 1em;
			}
		}
		.carousel-indicators {
			top: 15em;
		}
	}
}

// Large devices (desktops, less than 75em)
@media (max-width: 74.9em) {
	#home-carousel {

		.carousel-caption {
			top: 6em;
			a {
			}
			h2 {
			    font-size: 2em;
			}
		}
		.carousel-indicators {
			top: 19em;
		}
	}
}

nav.producthunt {
	margin-top: 4em;
	background: #ce2a4a;
	a {
		padding: 0.8em;
		display: block;
		color: #fff;
		font-size: 1.25em;
	}
}
